<template>
  <div class="selector">
    <div class="header">
      <div class="title">选择图层</div>
    </div>

    <div
      class="item"
      v-for="(item, index) in list"
      :key="item"
      :class="activeIndex === index ? 'active' : ''"
      @click="$emit('change', index)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      list: ["管线养护", "管线巡查"],
    };
  },
};
</script>


<style lang="less" scoped>
.selector {
  position: fixed;
  top: 11vh;
  left: 1vw;
  z-index: 99;
  background-color: var(--bg-color);

  width: 15vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 1vh 1vw;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;

    &:last-child {
      border-bottom-width: 0;
    }
  }

  .active {
    color: var(--theme-color);
  }
}
</style>
