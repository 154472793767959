<template>
  <div>
    <div id="map"></div>
    <Selector
      :activeIndex="activeIndex"
      @change="(payload) => (activeIndex = payload)"
    />
    <Maintain v-if="activeIndex === 0" />
    <Patrol v-if="activeIndex === 1" />
  </div>
</template>
<script>
import Selector from "./components/selector.vue";
import Maintain from "./components/maintain";
import Patrol from "./components/patrol";
export default {
  components: {
    Selector,
    Maintain,
    Patrol,
  },

  data() {
    return {
      activeIndex: 0,
    };
  },

  mounted() {
    this.initMap();
  },

  beforeDestroy() {
    this.map?.destroy();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 15,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });

      // AMap.plugin(["AMap.Scale", "AMap.HawkEye"], function () {
      //   map.addControl(new AMap.Scale());
      //   map.addControl(new AMap.HawkEye({ isOpen: true }));
      // });

      this.map = map;
    },
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 10;
}
</style>