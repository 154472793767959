var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selector"},[_vm._m(0),_c('div',{staticClass:"query"},[_c('a-form-model',{attrs:{"layout":"inline"}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"巡查人"},model:{value:(_vm.queryForm.name),callback:function ($$v) {_vm.$set(_vm.queryForm, "name", $$v)},expression:"queryForm.name"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"巡查类型"},model:{value:(_vm.queryForm.type),callback:function ($$v) {_vm.$set(_vm.queryForm, "type", $$v)},expression:"queryForm.type"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("日常巡查")])],1)],1),_c('a-form-model-item',[_c('a-range-picker',{staticStyle:{"width":"220px"},model:{value:(_vm.queryForm.date),callback:function ($$v) {_vm.$set(_vm.queryForm, "date", $$v)},expression:"queryForm.date"}})],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("新增巡查信息")])],1),_c('div',{staticClass:"table"},[_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"巡查人","data-index":"name","align":"center"}}),_c('a-table-column',{attrs:{"title":"巡查类型","data-index":"type","align":"center"}}),_c('a-table-column',{attrs:{"title":"巡查方式","data-index":"method","align":"center"}}),_c('a-table-column',{attrs:{"title":"问题描述","data-index":"remarks"}}),_c('a-table-column',{attrs:{"title":"创建时间","data-index":"date","align":"center"}})],1)],1),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"创建养护计划","footer":null,"width":"600px"},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"巡查人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [{ required: true, message: '请输入' }],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [{ required: true, message: '请输入' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"巡查类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [{ required: true, message: '请选择' }],
            },
          ]),expression:"[\n            'type',\n            {\n              rules: [{ required: true, message: '请选择' }],\n            },\n          ]"}],staticStyle:{"width":"100%"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("日常巡查")])],1)],1),_c('a-form-item',{attrs:{"label":"巡查方式"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'method',
            {
              rules: [{ required: true, message: '请选择' }],
            },
          ]),expression:"[\n            'method',\n            {\n              rules: [{ required: true, message: '请选择' }],\n            },\n          ]"}],staticStyle:{"width":"100%"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("步行")])],1)],1),_c('a-form-item',{attrs:{"label":"问题描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'detail',
            {
              rules: [{ required: true, message: '请输入' }],
            },
          ]),expression:"[\n            'detail',\n            {\n              rules: [{ required: true, message: '请输入' }],\n            },\n          ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1),_c('a-form-item',{attrs:{"label":"巡查图片或视频"}},[_c('div',{staticClass:"uploader"},[_c('a-icon',{attrs:{"type":"plus"}})],1)]),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交")])],1)],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("管线巡查")])])
}]

export { render, staticRenderFns }