<template>
  <div class="selector">
    <div class="header">
      <div class="title">管线养护</div>
    </div>

    <div class="query">
      <a-form-model layout="inline">
        <a-form-model-item>
          <a-input
            v-model="queryForm.name"
            placeholder="养护计划名称"
            style="width: 150px"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="queryForm.type"
            placeholder="养护计划类型"
            style="width: 150px"
          >
            <a-select-option value="1">仪表校正</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-range-picker v-model="queryForm.date" style="width: 220px" />
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="queryForm.status"
            placeholder="养护状态"
            style="width: 150px"
          >
            <a-select-option value="1">待养护</a-select-option>
            <a-select-option value="2">养护中</a-select-option>
            <a-select-option value="3">养护完成</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <a-button type="primary" @click="add">创建养护计划</a-button>
    </div>

    <div class="table">
      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="养护计划名称" data-index="name" />
        <a-table-column title="养护计划类型" data-index="type" />
        <a-table-column title="位置" data-index="location" />
        <a-table-column title="开始截至日期" data-index="date" />
        <a-table-column title="计划详情" data-index="remarks" />
        <a-table-column title="养护状态" data-index="status" />
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="editText(text)">编辑</a>
              <a href="#" class="danger" @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>

    <a-modal
      :visible="visible"
      title="创建养护计划"
      :footer="null"
      width="600px"
      @cancel="cancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <!-- initialValue: text.invoiceCode, -->
        <a-form-item label="计划名称">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="计划类型">
          <a-select
            v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option value="1">仪表校正</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="开始截至日期">
          <a-range-picker
            v-decorator="[
              'date',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="位置">
          <a-input
            v-decorator="[
              'location',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="计划详情">
          <a-textarea
            :auto-size="{ minRows: 3 }"
            v-decorator="[
              'detail',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="养护状态">
          <a-select
            v-decorator="[
              'status',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option value="1">待养护</a-select-option>
            <a-select-option value="2">养护中</a-select-option>
            <a-select-option value="3">养护完成</a-select-option>
          </a-select>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryForm: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [
        {
          name: "日常养护",
          type: "仪表校正",
          location: "厂区",
          date: "2022/06/22-2022-06-23",
          remarks: "--",
          status: "进行中",
        },
        {
          name: "日常养护",
          type: "仪表校正",
          location: "厂区",
          date: "2022/06/22-2022-06-23",
          remarks: "--",
          status: "进行中",
        },
      ],
      total: 0,

      form: this.$form.createForm(this),
      visible: false,
    };
  },

  methods: {
    query() {},
    reset() {
      this.queryForm = {};
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
    },
    editText() {},
    deleteText() {},

    add() {
      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          this.cancel();
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.selector {
  position: fixed;
  top: 11vh;
  left: 17vw;
  right: 1vw;
  z-index: 99;
  background-color: var(--bg-color);

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .query {
    padding: 2vh 1vw 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table {
    padding: 2vh 1vw;
  }
}
</style>