<template>
  <div class="selector">
    <div class="header">
      <div class="title">管线巡查</div>
    </div>

    <div class="query">
      <a-form-model layout="inline">
        <a-form-model-item>
          <a-input
            v-model="queryForm.name"
            placeholder="巡查人"
            style="width: 150px"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="queryForm.type"
            placeholder="巡查类型"
            style="width: 150px"
          >
            <a-select-option value="1">日常巡查</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-range-picker v-model="queryForm.date" style="width: 220px" />
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <a-button type="primary" @click="add">新增巡查信息</a-button>
    </div>

    <div class="table">
      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="巡查人" data-index="name" align="center" />
        <a-table-column title="巡查类型" data-index="type" align="center" />
        <a-table-column title="巡查方式" data-index="method" align="center" />
        <a-table-column title="问题描述" data-index="remarks" />
        <a-table-column title="创建时间" data-index="date" align="center" />
      </a-table>
    </div>

    <a-modal
      :visible="visible"
      title="创建养护计划"
      :footer="null"
      width="600px"
      @cancel="cancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <!-- initialValue: text.invoiceCode, -->
        <a-form-item label="巡查人">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="巡查类型">
          <a-select
            v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option value="1">日常巡查</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="巡查方式">
          <a-select
            v-decorator="[
              'method',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option value="1">步行</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="问题描述">
          <a-textarea
            :auto-size="{ minRows: 3 }"
            v-decorator="[
              'detail',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="巡查图片或视频">
          <div class="uploader">
            <a-icon type="plus" />
          </div>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryForm: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [
        {
          name: "黄强",
          type: "日常巡查",
          method: "步行",
          remarks: "--",
          date: "2022-06-22 12:31:20",
        },
        {
          name: "黄强",
          type: "日常巡查",
          method: "步行",
          remarks: "--",
          date: "2022-06-22 12:31:20",
        },
        {
          name: "黄强",
          type: "日常巡查",
          method: "步行",
          remarks: "--",
          date: "2022-06-22 12:31:20",
        },
        {
          name: "黄强",
          type: "日常巡查",
          method: "步行",
          remarks: "--",
          date: "2022-06-22 12:31:20",
        },
        {
          name: "黄强",
          type: "日常巡查",
          method: "步行",
          remarks: "--",
          date: "2022-06-22 12:31:20",
        },
      ],
      total: 0,

      form: this.$form.createForm(this),
      visible: false,
    };
  },

  methods: {
    query() {},
    reset() {
      this.queryForm = {};
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
    },
    editText() {},
    deleteText() {},

    add() {
      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          this.cancel();
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.selector {
  position: fixed;
  top: 11vh;
  left: 17vw;
  right: 1vw;
  z-index: 99;
  background-color: var(--bg-color);

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .query {
    padding: 2vh 1vw 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table {
    padding: 2vh 1vw;
  }
}

.uploader {
  border: 1px solid #e0e0e0;
  width: 120px;
  height: 120px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #999;
  cursor: pointer;
}
</style>